/*
|==========================================================================
| WordPress + Foundation
|==========================================================================
| 
| These functions make sure WordPress and Foundation play nice together,
| and this is also where we declare functions defined in this folder.
|
|==========================================================================
|
*/

document.addEventListener( "DOMContentLoaded", function() {

  // Evolving function for WCAG guidelines
  v8_wcag();

  // Detect browser and device
  detectBrowserAndDevice();

  // Enable skrollr
  // var s = skrollr.init();

  // Gravity Forms Placeholder Clearing
  // gravity_forms_placeholders();

  // Inline SVG replacement
  // inline_svg_replacement();

  // Isotope Masonry
  // isotope_masonry();

  // TablePress Cleanup
  // tablepress();

  // Event Target Testing
  jQuery(document).click(function(event) {

  });

  // Window scroll detection
  jQuery(window).bind("scroll", function(event) {        

  });

  // Remove empty P tags created by WP inside of Accordion and Orbit
  jQuery('.accordion p:empty, .orbit p:empty').remove();

	 // Makes sure last grid item floats left
  jQuery('.archive-grid .columns').last().addClass( 'end' );

	// Adds Flex Video to YouTube and Vimeo Embeds
  jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
    if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
      jQuery(this).wrap("<div class='widescreen flex-video'/>");
    } else {
      jQuery(this).wrap("<div class='flex-video'/>");
    }
  });

  jQuery('.slider').slick({
    autoplay: false,
    dots: false,
    // appendDots: '.nav-dots',
    // prevArrow: true,
    // nextArrow: true,
    infinite: true,
    speed: 700,
    fade: true,
    cssEase: 'linear',
    // responsive: [
    // {
    //   breakpoint: 641,
    //   settings: "unslick"
    // }]
  });

});

/*
|==========================================================================
| Use this function to fire events after the entire window has loaded,
| including images and CSS files.
|==========================================================================
*/

window.onload = function() {
  AOS.init({

  // Global settings:
  disable: 'mobile', // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  // startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  // initClassName: 'aos-init', // class applied after initialization
  // animatedClassName: 'aos-animate', // class applied on animation
  // useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  // disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  // debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  // throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  // offset: 120, // offset (in px) from the original trigger point
  // delay: 0, // values from 0 to 3000, with step 50ms
  // duration: 400, // values from 0 to 3000, with step 50ms
  // easing: 'ease', // default easing for AOS animations
  // once: false, // whether animation should happen only once - while scrolling down
  // mirror: false, // whether elements should animate out while scrolling past them
  // anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation);
});

};
