/*
|==========================================================================
| Volum8 WCAG
|==========================================================================
| 
| These are various functions gathered over time enhance basic
| accessibility of websites by removing empty elements, adding an
| alert when leaving the site, iframe accessibility. Currently a
| work in progress, and probably needs review.
|
|==========================================================================
|
*/

function v8_wcag() {

  // Remove noscript elements
  // jQuery("noscript").remove();

  // Empty element removal
  jQuery('h1,h2,h3,h4,h5,h6,a.gform_anchor,label').each(function(i){
    var element_to_possibly_remove = jQuery(this);
    var elements_text_length = element_to_possibly_remove.text().trim().length;
    if (elements_text_length === 0) {
      element_to_possibly_remove.remove();
    }
  });

  // iframe Accessibility
  jQuery('iframe').each(function(i){
    if(!jQuery(this).attr('title')) {
      jQuery(this).attr('title','Inline frame for form functionality');
      jQuery(this).attr('aria-hidden','true');
    }
  });

  // Remove invalid aria-hidden attributes for script files
  jQuery('script').each(function(i){
    if(jQuery(this).attr('aria-hidden')) {
      jQuery(this).removeAttr('aria-hidden');
    }
  });

  // Login remember me
  jQuery('#rememberme').each(function(i){
    var remember_chk_parent = jQuery(this).parent();
    remember_chk_parent.attr('for','rememberme');
  });

  // ADA Compliance for Single Event page input field
  jQuery('.page-template-template-events .rsvp_manual input[type=text], .page-template-template-events .comment_specialbox textarea').each(function() {

    if (jQuery(this).attr('data-slug')) {
      jQuery(this).attr('title', jQuery(this).attr('data-slug'));
    } else {
      jQuery(this).attr('title', jQuery(this).attr('id'));
    }

  });

  // ADA Compliance for Single Event page input field
  jQuery('input[type=text], textarea').each(function() {
    
    var input_element = jQuery(this);
    var input_id = input_element.attr('id');
    var input_placeholder = input_element.attr('placeholder');
    var label_selector = jQuery("label[for*='" + input_id + "']");

    if (label_selector.length > 0) {
    
    } else {

      jQuery("<label class='hide' for='" + input_id + "'>" + input_placeholder + "</label>").insertAfter(input_element);

    }
    
  });

  // ==================================================================== //
  // Leaving Site Script to trigger foundation modal
  // Allows for the notification and download of PDF documents as well
  // ==================================================================== //

  // Get hostname to detect internal links
  var hostname = window.location.hostname;

  // Variable for main content wrapper
  var container = "body";

  // Variable for website name; "You are about to leave the website_name Web Site" message
  var website_name = "SocialService Società Cooperativa Sociale onlus";

  // Add class ".external" to all anchors that begin with "http://" or "https://" inside site wrapper
  // Force auto open in new window

  jQuery(container + " a[href^='http://']").attr("target","_blank");
  jQuery(container + " a[href^='http://']").attr("rel","noreferrer noopener");
  jQuery(container + " a[href^='http://']").addClass("external");
  jQuery(container + " a[href^='https://']").attr("target","_blank");
  jQuery(container + " a[href^='https://']").attr("rel","noreferrer noopener");
  jQuery(container + " a[href^='https://']").addClass("external");

  // Add class "internal" if "http://"" starts with the hostname, and prevent opening in new window
  jQuery(container + " a[href^=\'http://"+hostname+"/\']").removeClass("external").removeAttr('target').addClass("internal");
  jQuery(container + " a[href^=\'https://"+hostname+"/\']").removeClass("external").removeAttr('target').addClass("internal");

  // Log all external links with class "external" for debugging
  // jQuery( "a.external" ).each(function( index ) {
  //   console.log( index + ": Link text: " + jQuery( this ).text());
  //   console.log( index + ': Link href: ' + jQuery( this ).attr("href") );
  // });

  jQuery(".external").click(function(e){

    // Get href attribute of anchor
    var href = jQuery(this).attr('href');

    // Check if url is a link to a PDF
    var pdf = href.search(".pdf");


    if(pdf > 0) {
      jQuery('#leaving-site .dialog-msg').html("<p>Stai per scaricare un&nbsp;PDF.</p>");
      jQuery('#leaving-site .destination').attr('href',href);
      jQuery('#leaving-site .location').html(href);
      jQuery('#leaving-site').foundation('open');
      return false;
    } else {
      jQuery('#leaving-site .dialog-msg').html("<p>Stai per lasciare <br>" + website_name + " Sito web.<br><br>Desideri continuare?</p>");
      jQuery('#leaving-site .destination').attr('href',href);
      jQuery('#leaving-site .location').html(href);
      jQuery('#leaving-site').foundation('open');
      return false;
  }
});

  // Cleanup orphaned form labels
  jQuery('label').each(function() {
    if(!jQuery(this)[0].hasAttribute("for")) {
      jQuery(this).contents().unwrap();
    } 
  });

  // Form Cleanup for New Account Inquiry
  jQuery("#input_3_6_1_label").remove();
  jQuery("label[for='input_3_7_3']").remove();

  // Alert dialog issues
  setTimeout(function(){
    jQuery('.rsvp_alert_dialog .close .link-skew, .rsvp_success_dialog .close .link-skew').text('Dismiss');
  },500);

  // RSVP Fixes

  jQuery("#rsvp_next").attr('href','#');
  
  // Detect button Enter when you click with your keyboard
  jQuery(".dropdown li > ul li a").keydown(function(e) {
      if(e.keyCode == 13) {
          var link_to_follow = jQuery(this).attr('href');
          document.location.href = link_to_follow;
          return true;
      }
  });

  // Search form label
  jQuery("form.search-form").each(function(i){
    jQuery(this).find(".search-field").attr('id','search-field'+i);
    jQuery(this).find("span.screen-reader-text").wrap('<label for="search-field'+i+'"></label>');
  });

  // RSVP Keyboard connection

  setTimeout(function(){
    jQuery(".rsvp_namespace").wrapInner('<a href="#"></a>');  

    jQuery(".rsvp_namespace a").keydown(function(e) {
      if(e.keyCode == 13) {
        console.log('enter key pressed'); 
        jQuery(this).parent().click(); 
      }
      e.stopPropagation();
      e.preventDefault();
      return false;
    });

  },500);

  // Gravity form fieldset for radio buttons
  setTimeout(function(){
    jQuery('.ginput_container_radio').each(function(i){
      var this_radio = jQuery(this);
      var radio_parent = jQuery(this).parent();
      //console.log(radio_parent); 
      var fieldset_name = jQuery(this).parent().clone()    //clone the element
                                                .children() //select all the children
                                                .remove()   //remove all the children
                                                .end()  //again go back to selected element
                                                .text();
                                                console.log(fieldset_name);
      radio_parent.html('<fieldset name="'+fieldset_name+'"><legend>'+fieldset_name+'</legend></fieldset>').find('fieldset').append(this_radio);
      setTimeout(function(){
        jQuery(this).find('fieldset').prepend('');
      },250);
    });
  },500);
  // Gravity Select Dropdown optgroup wrapper
  setTimeout(function(){
    jQuery('.ginput_complex select:not(#input_3_6_4)').each(function(i){
      jQuery(this).wrapInner('<optgroup></optgroup>');
    });
    // jQuery("#input_3_6_4 optgroup").attr('label','States'); 
  },500);
  
  if(jQuery("#hero-video-player").length > 0) {
    setInterval(function(){ 
      v = document.getElementById("hero-video-player");
      v.textTracks[0].mode = "hidden";  // "showing" will make them reappear
      // if you want to show the controls
      v.controls = false; 
    },1);
  }

  // Job Filters
  jQuery('.job_filters').append('<button class="hide" type="submit">Submit</button>');
  jQuery('.job_types').wrap('<fieldset></fieldset>').prepend('<legend class="show-for-sr">Job Types</legend>');

  // Tables
  setInterval(function(){
    jQuery('table').attr('role','presentation');
    jQuery('table tr').attr('role','row');
    jQuery('table th').attr('role','columnheader');
    jQuery('table td').attr('role','gridcell');
  },1);  

  // DD Slick
  jQuery('a.dd-option').attr('href','#');
  jQuery('a.dd-option').attr('onclick','return false;');

}
